import { ReactNode } from "react"
import {
	Flex,
	Link,
	Stack,
	Image,
	Text,
	VStack,
	StyleProps,
	Heading,
} from "@chakra-ui/react"
import Spark from "../../assets/Spark.svg"
import EllipseFooterR from "../../assets/EllipseFooterR.svg"
import { Icon } from "@iconify/react"

const LinkFooter = ({
	children,
	styles,
}: {
	children: ReactNode
	styles?: StyleProps
}) => (
	<Link
		rounded={"md"}
		_hover={{
			textDecoration: "none",
			opacity: 0.5,
		}}
		href={"#"}
		fontSize={"18px"}
		{...styles}
	>
		{children}
	</Link>
)

export function Footer() {
	return (
		<Flex
			position={"relative"}
			h={{
				base: "auto",
				lg: "300px",
			}}
			overflow={{
				base: "visible",
				lg: "hidden",
			}}
			alignItems={"center"}
			justifyContent={"center"}
			flexDir={"column"}
			gap={"20px"}
			py={{
				base: "20px",
				lg: 0,
			}}
		>
			<Image
				src={EllipseFooterR}
				position={"absolute"}
				w={"1000px"}
				top={{
					base: "0",
					lg: "58%",
				}}
				right={{
					base: 0,
					lg: "-5%",
				}}
				transform={"translateY(-50%) scaleX(-1)"}
				zIndex={1}
			/>
			<Image
				src={EllipseFooterR}
				position={"absolute"}
				w={"1000px"}
				top={{
					base: "100%",
					lg: "55%",
				}}
				left={{
					base: 1,
					lg: "-5%",
				}}
				transform={"translateY(-50%) rotate(-3deg)"}
				zIndex={1}
			/>

			<Flex
				w={"full"}
				bg={
					" linear-gradient(255.06deg, #4694ED 7.27%, #74EBD5 103.51%)"
				}
				justifyContent={"space-between"}
				px={70}
				py={10}
				h={{
					base: "auto",
					lg: "200px",
				}}
				alignItems={"center"}
				flexDirection={{
					base: "column",
					lg: "row",
				}}
				gap={{
					base: 8,
					lg: 0,
				}}
				position={"relative"}
				zIndex={10}
			>
				<Image src={Spark} w={"135px"} />

				<Stack
					textAlign={{
						base: "center",
						lg: "initial",
					}}
				>
					<LinkFooter>Sobre Nós</LinkFooter>
					<LinkFooter>Política de Privacidade</LinkFooter>
					<LinkFooter>Termos de Uso</LinkFooter>
				</Stack>
				<Stack>
					<Heading fontSize={18}>Redes Sociais</Heading>
					<LinkFooter styles={{ display: "flex", gap: "10px" }}>
						<Icon
							icon="ic:baseline-facebook"
							width={"25px"}
							fontSize={"15px"}
						/>
						<Text>Facebook</Text>
					</LinkFooter>
					<LinkFooter styles={{ display: "flex", gap: "10px" }}>
						<Icon
							icon="mdi:instagram"
							width={"25px"}
							fontSize={"15px"}
						/>
						<Text>Instagram</Text>
					</LinkFooter>
				</Stack>
				<VStack>
					<Icon
						icon="tabler:rating-18-plus"
						fontSize={"44px"}
						fontWeight={"100"}
					/>
					<Text>Para maiores de 18 anos</Text>
				</VStack>
			</Flex>
			<Text>Todos os Direitos Reservados *Spark</Text>
		</Flex>
	)
}
