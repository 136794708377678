import {
	Flex,
	useBreakpointValue,
	Image,
	Text,
	VStack,
	Heading,
	Box,
} from "@chakra-ui/react"
import firstBg from "../../images/FirstSectionBg.png"
import SecondBg from "../../images/SecondSectionBg.png"
import SparkCircle from "../../assets/SparkCircle.svg"
import Ellipse from "../../assets/Ellipse.svg"
import EllipseGreen from "../../assets/EllipseGreen.svg"
import Rocket from "../../images/rocket.png"
import Coins from "../../images/Coins.png"
import Joystick from "../../images/Joystick.png"
import Pc from "../../images/Pc.png"
import { ExperimentButton } from "./ExperimentButton"
import { Card } from "./Cards"
import {
	KeyframesSparkCircle,
	KeyframesCoins,
	KeyframesEllipse,
	KeyframesRakingEg,
	KeyframesRakingE,
	KeyframesStar,
} from "./keyframes"
import { Comments } from "../Comments"
import { RankingCard } from "./RankingCard"
import { CardBeneficit } from "./CardBeneficit"
import { Icon } from "@iconify/react"

export function Main() {
	const animationSparkCircle = `${KeyframesSparkCircle} infinite 5s linear`
	const animationCoins = `${KeyframesCoins} infinite 4s linear`
	const animationRocket = `${KeyframesCoins} infinite 8s linear`
	const animationEllipse = `${KeyframesEllipse} infinite 6s linear`
	const animationRakingEG = `${KeyframesRakingEg} infinite 6s linear`
	const animationRakingE = `${KeyframesRakingE} infinite 6s linear`
	const animationStar = `${KeyframesStar} infinite 3s linear`
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
		lg: false,
	})
	const px = {
		base: "30px",
		sm: 70,
		lg: 120,
	}

	return (
		<Flex flexDir={"column"}>
			{/* First Section */}
			<Flex
				bg={`url(${firstBg})`}
				w={"full"}
				px={px}
				pr={{
					base: 30,
					sm: 50,
				}}
				py={{
					base: 57,
					md: 100,
				}}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Flex flexDir={"column"} gap={8} py={12} position={"relative"}>
					<Flex
						position={"absolute"}
						w={600}
						h={600}
						borderRadius={"50%"}
						opacity={0.5}
						filter={"blur(150px)"}
						pointerEvents={"none"}
						bg={"#4694ED"}
						top={0}
						left={0}
						zIndex={1}
						transform={"translate(-70%, 0) !important"}
					/>

					<Box
						pt={"30px"}
						position={"relative"}
						w={"min-content"}
						zIndex={1}
					>
						<Heading
							fontWeight={900}
							fontSize={{
								base: 50,
								lg: 70,
							}}
						>
							SPARK
						</Heading>
						<Image
							src={SparkCircle}
							position={"absolute"}
							bottom={{
								base: 3,
								md: 0,
							}}
							left={0}
							zIndex={"-1"}
							w={{
								base: 100,
								md: 120,
							}}
							animation={animationSparkCircle}
							marginLeft={{
								base: "-45px",
								md: "-70px",
							}}
						/>
					</Box>
					<Heading
						fontSize={{
							base: 30,
							lg: 40,
						}}
					>
						INOVAÇÃO
						<Text
							fontWeight={700}
							style={{
								background:
									"-webkit-linear-gradient(255.06deg, #4694ED 7.27%, #74EBD5 103.51%)",
								WebkitBackgroundClip: "text",
								WebkitTextFillColor: "transparent",
							}}
						>
							AUTOMAÇÃO
						</Text>
						E LUCRO
					</Heading>
					<Flex
						maxW={530}
						fontSize={{
							base: 14,
							sm: 16,
							lg: 19,
						}}
						pl={30}
						px={15}
						py={3}
						textAlign={"justify"}
						borderLeft={"3px solid"}
						sx={{
							borderImageSource:
								"linear-gradient(360deg, #4694ED -32.06%, #74EBD5 151.96%);",
							borderImageSlice: 1,
						}}
					>
						Transforme suas apostas em uma fonte de renda
						consistente com Spark. Nossa tecnologia de automação
						inteligente otimiza suas estratégias e aumenta seus
						lucros. Junte-se e experimente a vantagem máxima na
						indústria de apostas.
					</Flex>
					<ExperimentButton />
				</Flex>
				{!isMobile ? (
					<Flex position={"relative"} w={550} h={550}>
						<Image
							src={Rocket}
							maxH={430}
							h={{
								base: 300,
								lg: 400,
								xl: 430,
							}}
							position={"absolute"}
							top={"50%"}
							left={"50%"}
							zIndex={2}
							transform={"translate(-50%, -50%)"}
							animation={animationRocket}
						/>
						<Image
							src={Coins}
							position={"absolute"}
							zIndex={1}
							animation={animationCoins}
							top={"50%"}
							left={"50%"}
							transform={"translate(-50%, -50%)"}
						/>
						<Image
							src={Ellipse}
							position={"absolute"}
							zIndex={0}
							animation={animationEllipse}
							top={"25%"}
							left={"32%"}
							// transform={"translate(-50%, -50%)"}
						/>
						<Flex
							position={"absolute"}
							w={300}
							h={300}
							borderRadius={"50%"}
							opacity={1}
							filter={"blur(200px)"}
							pointerEvents={"none"}
							bg={"#74EBD5"}
							zIndex={2}
							top={"50%"}
							left={"50%"}
							transform={"translate(-50%, -50%)"}
						/>
					</Flex>
				) : null}
			</Flex>
			{/* Our Resources Section */}
			<Flex
				bg={`url(${SecondBg})`}
				alignItems={"center"}
				justifyContent={"center"}
				flexDir={"column"}
				w={"full"}
				zIndex={0}
				py={{
					base: 50,
					md: 100,
				}}
				px={px}
				gap={10}
			>
				<Flex
					flexDir={"column"}
					textAlign={"center"}
					borderBottom={"3px solid"}
					position={"relative"}
					py={2}
					sx={{
						borderImageSource:
							"linear-gradient(360deg, #4694ED -32.06%, #74EBD5 151.96%);",
						borderImageSlice: 1,
					}}
				>
					<Flex
						position={"absolute"}
						w={350}
						h={350}
						borderRadius={"50%"}
						opacity={0.5}
						filter={"blur(100px)"}
						pointerEvents={"none"}
						bg={"#4694ED"}
						top={"50%"}
						left={"50%"}
						zIndex={1}
						transform={"translate(-50%, -50%) !important"}
					/>
					<Heading
						style={{
							WebkitTextFillColor: "transparent",
							WebkitTextStrokeWidth: 1,
						}}
						lineHeight={"30px"}
						fontSize={{
							base: 40,
							sm: 50,
						}}
					>
						NOSSOS
					</Heading>
					<Heading
						fontSize={{
							base: 40,
							sm: 50,
						}}
						lineHeight={"35px"}
					>
						RECURSOS
					</Heading>
				</Flex>

				<Flex
					justifyContent={"center"}
					w={"full"}
					flexDir={{
						base: "column",
						md: "row",
					}}
					alignItems={"center"}
					gap={{
						base: 5,
						md: 5,
						lg: 10,
					}}
				>
					<Card
						icon={Joystick}
						title="Jogue como um verdadeiro campeão"
						describe="Programar padrões de compra e venda de ativos nunca foi tão fácil, com intervalos de tempo pré-definidos, incluindo o rápido 1 minuto, o estratégico 5 minutos, o dinâmico 15 minutos e o longo 1 hora."
					/>
					<Card
						icon={Pc}
						title="Tome decisões informadas"
						describe=" E você nunca vai entrar em uma batalha financeira sem informações precisas, com acesso às análises de mercado mais atualizadas. Vença a cada jogada e alcance lucros incríveis com Spark!"
					/>
				</Flex>
			</Flex>
			{/* Beneficit Section */}
			<VStack w={"full"} bg={`url(${SecondBg})`} spacing={12}>
				<Heading
					alignSelf={"center"}
					fontSize={{
						base: 40,
						sm: 50,
					}}
					lineHeight={"35px"}
					position={"relative"}
				>
					Benefícios
					<Box
						style={{
							position: "absolute",
							top: "-100%",
							right: "-10%",
						}}
						animation={animationStar}
					>
						<Icon
							icon={"mdi:star-four-points"}
							color={"#F0AE53"}
							fontSize={"30px"}
						/>
					</Box>
				</Heading>
				<Flex
					alignItems={"center"}
					justifyContent={"center"}
					gap={8}
					px={px}
					flexDir={{
						base: "column",
						lg: "row",
					}}
				>
					<CardBeneficit
						title="Eficiência"
						describe="A nossa tecnologia de automatização de apostas pode agilizar e tornar mais precisa a realização de investimentos financeiros, otimizando o tempo e os esforços dos investidores. "
						icon="icon-park-outline:database-time"
					/>
					<CardBeneficit
						title="Diversificação"
						describe="Com a Spark automatização de apostas, é possível diversificar os investimentos em diferentes instrumentos financeiros, aumentando a segurança e minimizando os riscos."
						icon="bi:currency-exchange"
					/>
					<CardBeneficit
						title="Controle"
						describe="A nossa tecnologia de automatização de apostas pode agilizar e tornar mais precisa a realização de investimentos financeiros, otimizando o tempo e os esforços dos investidores. "
						icon="fa6-solid:hand-holding-dollar"
					/>
				</Flex>
				<Flex
					px={px}
					alignItems={"center"}
					justifyContent={"center"}
					py={10}
					w={"full"}
				>
					<Flex
						justifyContent={"space-between"}
						alignItems={"center"}
						bg={"rgba(70, 148, 237, 0.1)"}
						py={"25px"}
						px={"50px"}
						gap={27}
						borderRadius={12}
						w={{
							base: "full",
							sm: "330px",
							md: "full",
						}}
						flexDir={{
							base: "column",
							md: "row",
						}}
					>
						<VStack>
							<Heading
								fontSize={{
									base: 30,
									md: 35,
								}}
								lineHeight={"25px"}
							>
								24H
							</Heading>
							<Text fontSize={16}>De Suporte</Text>
						</VStack>
						<VStack>
							<Heading
								fontSize={{
									base: 30,
									md: 35,
								}}
								lineHeight={"25px"}
							>
								35.678
							</Heading>
							<Text fontSize={16}>Usuários Cadastrados</Text>
						</VStack>
						<VStack>
							<Heading
								fontSize={{
									base: 30,
									md: 35,
								}}
								lineHeight={"25px"}
							>
								Confira
							</Heading>
							<Text fontSize={16}>As Atualizações</Text>
						</VStack>
					</Flex>
				</Flex>
			</VStack>

			{/* Ranking Section */}
			<Flex
				flexDir={"column"}
				py={12}
				alignItems={"center"}
				gap={12}
				position={"relative"}
				w={"full"}
				bg={`url(${SecondBg})`}
			>
				<VStack>
					<Heading
						fontSize={{
							base: 40,
							sm: 50,
						}}
						lineHeight={"35px"}
					>
						RANKING
					</Heading>
					<Heading
						style={{
							WebkitTextFillColor: "transparent",
							WebkitTextStrokeWidth: 1,
						}}
						lineHeight={"30px"}
						fontSize={{
							base: 40,
							sm: 50,
						}}
					>
						SEMANAL
					</Heading>
				</VStack>
				<Flex
					position={"absolute"}
					w={"full"}
					maxW={450}
					h={450}
					borderRadius={"50%"}
					opacity={0.5}
					filter={"blur(100px)"}
					bg={"#4694ED"}
					top={"0"}
					left={"50%"}
					pointerEvents={"none"}
					zIndex={0}
					transform={"translate(-50%, 0%) !important"}
				/>
				<Flex
					position={"absolute"}
					w={700}
					h={700}
					borderRadius={"50%"}
					opacity={0.4}
					filter={"blur(250px)"}
					bg={"#74EBD5"}
					zIndex={1}
					top={"80%"}
					pointerEvents={"none"}
					left={"-30%"}
					transform={"translate(0%, -30%)"}
				/>

				<Image
					src={Ellipse}
					position={"absolute"}
					zIndex={0}
					animation={animationRakingE}
					top={{
						base: "15%",
						md: "40%",
					}}
					left={"15%"}
				/>
				<Image
					src={EllipseGreen}
					position={"absolute"}
					zIndex={0}
					animation={animationRakingEG}
					top={{
						base: "100%",
						md: "65%",
					}}
					right={{
						base: 0,
						md: "5%",
					}}
				/>
				<RankingCard ranking={1} name="Marcelo Dazz" />
				<RankingCard ranking={2} name="Marcelo Dazz" />
				<RankingCard ranking={3} name="Marcelo Dazz" />
				<RankingCard ranking={4} name="Marcelo Dazz" />
				<RankingCard ranking={5} name="Marcelo Dazz" />
				<RankingCard ranking={6} name="Marcelo Dazz" />
			</Flex>
			{/* FAQ Section */}
			<Flex
				flexDir={"column"}
				alignItems={"center"}
				py={100}
				position={"relative"}
				bg={`url(${SecondBg})`}
			>
				<Flex
					position={"absolute"}
					w={350}
					h={350}
					borderRadius={"50%"}
					opacity={0.5}
					filter={"blur(100px)"}
					pointerEvents={"none"}
					bg={"#4694ED"}
					bottom={"0"}
					right={"0"}
					zIndex={1}
					// transform={"translate(-50%, -50%) !important"}
				/>
				<Flex>
					<Heading
						style={{
							WebkitTextFillColor: "transparent",
							WebkitTextStrokeWidth: 1,
						}}
						lineHeight={"30px"}
						fontSize={{
							base: 40,
							sm: 50,
						}}
					>
						Dúvidas
					</Heading>
					<Heading
						fontSize={{
							base: 40,
							sm: 50,
						}}
						lineHeight={"35px"}
					>
						?
					</Heading>
				</Flex>
				<Flex
					w={"full"}
					px={{
						base: "30px",
						sm: 70,
						lg: 120,
					}}
					flexDir={"column"}
					gap={"20px"}
					py={10}
				>
					<Comments
						title="Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio?"
						describe="Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio?"
					/>
					<Comments
						title="Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio?"
						describe="Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio?"
					/>
					<Comments
						title="Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio?"
						describe="Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio? Lorem ipsum dolor sit amet. Ea animi modi in corrupti possimus cum distinctio?"
					/>
				</Flex>
			</Flex>
		</Flex>
	)
}
