import { VStack, Image, Heading, Text } from "@chakra-ui/react"

export function Card({
	title,
	icon,
	describe,
}: {
	title: string
	icon: string
	describe: string
}) {
	return (
		<VStack
			bg={" rgba(70, 148, 237, 0.12);"}
			w={{
				base: "full",
				sm: 300,
				lg: 350,
				xl: 400,
			}}
			h={{
				base: "auto",
				sm: 450,
				md: 490,
				lg: 420,
			}}
			maxH={{
				sm: 430,
				md: 470,
			}}
			p={{
				base: 6,
				md: 8,
			}}
			px={10}
			borderRadius={12}
			justifyContent={"space-between"}
		>
			<Image src={icon} w={100} h={100} />
			<Heading
				fontSize={{
					base: 22,
					md: 28,
				}}
				textAlign={"center"}
				w={"80%"}
			>
				{title}
			</Heading>
			<Text
				fontSize={{
					base: 16,
					md: 17,
				}}
				textAlign={"justify"}
			>
				{describe}
			</Text>
		</VStack>
	)
}
