import { Button, Flex, Text } from "@chakra-ui/react"
import { Icon } from "@iconify/react"
export function ExperimentButton() {
	return (
		<Button
			role="group"
			border={"3px solid #F0AE53"}
			bg={"transparent"}
			h={50}
			w={250}
			display={"flex"}
			textAlign={"center"}
			overflowX={"hidden"}
			_hover={{
				background: "",
			}}
			_active={{
				background: "",
				opacity: 0.8,
			}}
			transition={"all 300ms ease-out"}
		>
			<Text fontSize={20} transition={"all 300ms ease-out"}>
				Experimente Agora
			</Text>
			<Flex
				animation={""}
				transform={"scale(0)"}
				w={0}
				transition={"all 300ms ease-out"}
				_groupHover={{
					transform: "scale(1)",
					width: "auto",
					marginLeft: 3,
				}}
			>
				<Icon
					icon="material-symbols:arrow-right-alt-rounded"
					fontSize={"30px"}
					
				/>
			</Flex>
		</Button>
	)
}
