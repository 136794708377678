import { Flex, Heading, Text } from "@chakra-ui/react"
import { Icon } from "@iconify/react"

export function CardBeneficit({
	title,
	icon,
	describe,
}: {
	title: string
	icon: string
	describe: string
}) {
	return (
		<Flex
			flexDir={"column"}
			maxW={"350px"}
			w={"full"}
			alignItems={"center"}
			justifyContent={"center"}
		>
			<Icon icon={icon} fontSize={"50px"} />
			<Flex
				flexDir={"column"}
				alignItems={"center"}
				justifyContent={"space-around"}
				textAlign={"justify"}
				border={"3px solid #4694ED"}
				p={"10px"}
				px={{
					base: "35px",
					sm: "20px",
					xl: "35px",
				}}
				minH={{
					base: "200px",
					lg: "240px",
					xl: "190px",
				}}
				// minH={"300px"}
				// style={{
				// 	aspectRatio: 16 / 9,
				// }}

				mt={5}
				borderRadius={12}
			>
				<Heading fontSize={"26px"}>{title}</Heading>
				<Text
					textAlign={"justify"}
					lineHeight={"18px"}
					fontSize={"15px"}
				>
					{describe}
				</Text>
			</Flex>
		</Flex>
	)
}
