import { ReactNode } from "react"
import {
	Box,
	Flex,
	HStack,
	Link,
	useDisclosure,
	useColorModeValue,
	useBreakpointValue,
	Stack,
	IconButton,
	Button,
	Image,
} from "@chakra-ui/react"
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons"
import Spark from "../../assets/Spark.svg"

const Links = ["Home", "Funcionalidades", "Serviço", "Ranking Semanal", "FAQ"]

const NavLink = ({ children }: { children: ReactNode }) => (
	<Link
		px={0}
		py={1}
		rounded={"md"}
		_hover={{
			textDecoration: "none",
			color: useColorModeValue("gray.200", "gray.400"),
		}}
		href={"#"}
	>
		{children}
	</Link>
)

export function Header() {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const isMobile = useBreakpointValue({
		base: true,
		lg: false,
	})

	const px = {
		base: 30,
		sm: 70,
	}
	return (
		<Flex
			position={"fixed"}
			justifyContent={"space-between"}
			flexDir={"column"}
			zIndex={999}
			w="full"
		>
			<Flex
				h={78}
				alignItems={"center"}
				justifyContent={"space-between"}
				w="full"
				px={px}
				bg={"rgba(255, 255, 255, 0.05)"}
				backdropFilter={"blur(15px)"}
			>
				<Image
					src={Spark}
					w={{
						base: 98,
						sm: 132,
					}}
				/>
				{isMobile ? (
					<IconButton
						size={"md"}
						bg={"none"}
						_hover={{
							opacity: 0.7,
						}}
						_active={{}}
						icon={
							isOpen ? (
								<CloseIcon fontSize={22} />
							) : (
								<HamburgerIcon fontSize={32} />
							)
						}
						aria-label={"Open Menu"}
						onClick={isOpen ? onClose : onOpen}
					/>
				) : (
					<HStack
						as={"nav"}
						spacing={8}
						display={{ base: "none", md: "flex" }}
					>
						{Links.map((link) => (
							<NavLink key={link}>{link}</NavLink>
						))}
						<Button
							bg={"transparent"}
							w={100}
							height={50}
							border={"3px solid #FFFFFF"}
							borderRadius={10}
							_hover={{
								background: "#fff",
								color: "#0f1211",
							}}
						>
							Login
						</Button>
					</HStack>
				)}
			</Flex>

			{isOpen && isMobile ? (
				<Box
					py={4}
					px={px}
					bg={"rgba(0, 0, 0, 0.15)"}
					backdropFilter={"blur(15px)"}
				>
					<Stack as={"nav"} spacing={4}>
						{Links.map((link) => (
							<NavLink key={link}>{link}</NavLink>
						))}
						<Button
							bg={"transparent"}
							w={"full"}
							height={50}
							border={"3px solid #FFFFFF"}
							borderRadius={10}
							_hover={{
								background: "#fff",
								color: "#0f1211",
							}}
						>
							Login
						</Button>
					</Stack>
				</Box>
			) : null}
		</Flex>
	)
}
