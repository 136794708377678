import * as React from "react"
// import "@fontsource/barlow"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { Main } from "./components/Main"
import { ChakraProvider, theme } from "@chakra-ui/react"
import { SparkTheme } from "./config/theme"
import "./styles.css"
export const App = () => (
	<ChakraProvider theme={SparkTheme}>
		<Header />
		<Main />
		<Footer />
	</ChakraProvider>
)
