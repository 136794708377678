import { keyframes } from "@emotion/react"

export const KeyframesSparkCircle = keyframes`
to {
    transform: rotate(0)

},
from {
    transform: rotate(360deg)

}
`
export const KeyframesCoins = keyframes`
0% {
    transform: translate(-50%, -50%)

},
50%{
    transform: translate(-50%, -48%)

},
100%{
    transform: translate(-50%, -50%) 

}
`
export const KeyframesEllipse = keyframes`
0% {
    
    transform: rotate(0) translate(-50%, -50%);
    opacity: 0.3;
    transform-origin: 20% 40%;

},
50%{
    transform: rotate(6deg) translate(-50%, -50%);
    opacity: 1;

},
100%{
    transform: rotate(0) translate(-50%, -50%);
    opacity: 0.3;
    transform-origin: 20% 40%;

}
`
export const KeyframesRakingEg = keyframes`
0% {
    
    transform: rotate(-10deg) translate(0, -50%);
    opacity: 0.3;
    transform-origin: 20% 40%;

},
50%{
    transform: rotate(-5deg) translate(0, -50%);
    opacity: 1;

},
100%{
    transform: rotate(-10deg) translate(0, -50%);
    opacity: 0.3;
    transform-origin: 20% 40%;

}
`
export const KeyframesRakingE = keyframes`
0% {
    
    transform: rotate(35deg) translate(-50%, -50%);
    opacity: 0.3;
    transform-origin: 20% 40%;

},
50%{
    transform: rotate(39deg) translate(-50%, -50%);
    opacity: 1;

},
100%{
    transform: rotate(35deg) translate(-50%, -50%);
    opacity: 0.3;
    transform-origin: 20% 40%;

}
`

export const KeyframesStar = keyframes`
0% {
    transform: scale(0.0)
},
50%{
    transform: scale(1)

},
100%{
    transform: scale(0.0)

}
`
