import { extendTheme } from "@chakra-ui/react";

export const SparkTheme = extendTheme({
    config: {
        useSystemColorMode: false,
        initialColorMode: "dark",
    },
    fonts: {
        body: "'Barlow', sans-serif",
        heading: "'Barlow', sans-serif",
    },
    styles: {
        global: {
            a: {
                textDecoration: "none",
                fontWeight: "500",
                fontSize: "16px"
            },
            "*": {
                fontWeight: "500",
            }
        },
    },
    components: {
        
    },
    colors: {
        
    },
    shadows: {
        outline: "none",
    },
});