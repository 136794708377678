import {
	Avatar,
	AvatarBadge,
	AvatarGroup,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react"
import { Icon } from "@iconify/react"
import Pc from "../../images/Pc.png"
export function RankingCard({
	ranking,
	name,
	user,
}: {
	ranking: number
	name: string
	user?: string
}) {
	return (
		<Flex
			gap={{
				base: 6,
				sm: 12,
			}}
			alignItems={"center"}
		>
			<Avatar
				name={name}
				size={{
					base: "lg",
					sm: "xl",
				}}
				bg={"#74EBD5"}
				color={"#fff"}
				src={user}
			/>
			<Flex
				w={{
					base: "190px",
					sm: "260px",
				}}
				h={{
					base: "80px",
					sm: "auto",
				}}
				bg={
					"linear-gradient(255.06deg, #4694ED 7.27%, #74EBD5 103.51%)"
				}
				transform={"skew(-25deg)"}
				borderRadius={10}
				p={{
					base: 3,
					sm: 4,
				}}
				px={{
					base: 5,
					sm: 10,
				}}
				flexDir={"column"}
				position={"relative"}
			>
				<Heading
					fontSize={{
						base: "24px",
						sm: "32px",
					}}
				>
					{`TOP ${ranking}`}
				</Heading>
				<Text
					fontSize={{
						base: "16px",
						sm: "22px",
					}}
				>
					{name}
				</Text>
				<Flex
					position={"absolute"}
					right={0}
					top={0}
					transform={"rotate(-135deg) skew(0)"}
				>
					<Icon
						icon="material-symbols:arrow-downward-rounded"
						fontSize={36}
					/>
				</Flex>
			</Flex>
		</Flex>
	)
}
