import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Heading,
} from "@chakra-ui/react"

export function Comments({
	title,
	describe,
}: {
	title: string
	describe: string
}) {
	return (
		<Accordion
			defaultIndex={[1]}
			allowMultiple
			w={"full"}
			bg={"  rgba(70, 148, 237, 0.12);"}
			borderRadius={"12px"}
			border={"none !important"}
			color={"#4694ED"}
		>
			<AccordionItem w={"full"} border={"none !important"}>
				<Heading w={"full"} fontSize={"22px"}>
					<AccordionButton
						_expanded={{ bg: "", color: "white" }}
						h={{
							base: "auto",
							md: 70,
						}}
					>
						<AccordionIcon
							mx={3}
							fontSize={{
								base: "25px",
								md: "40px",
							}}
						/>
						<Box
							as="span"
							flex="1"
							textAlign="left"
							fontSize={{
								base: "18px",
								md: "20px",
							}}
						>
							{title}
						</Box>
					</AccordionButton>
				</Heading>
				<AccordionPanel textAlign={"justify"}>
					{describe}
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}
